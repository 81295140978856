import React from 'react';
import Themes from '../../components/themes';

function Blog() {
    return (
        <div>
            <h1>
                Blog
            </h1>

            <Themes />
        </div>
    );
}

export default Blog;